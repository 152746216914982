<div
  class="dropdown"
  sdClickOutside
  (clickOutside)="close($event)"
  [ngClass]="{ expanded: isExpanded, disabled: disabled }"
  (click)="toggleDropdown($event)"
>
  <div class="select-header">
    <div class="select-header-value" [innerHtml]="valueOrPlaceholder"></div>
  </div>
  <ng-scrollbar class="list"  autoHeightDisabled="false">
    <div
      class="list-element"
      *ngFor="let option of options; trackBy: trackByIndex"
      (click)="setAsActive(option, additionalInfo, $event)"
      [ngClass]="{ 'bold-right': setToRightBold }"
    >
      <span
        [innerHtml]="optionFieldName ? option[optionFieldName] : option"
      ></span>
      <span class="additional-info bold" *ngIf="setToRightBold"
        >{{ additionalInfo ? showValueWithComma(option[additionalInfo]) : ""
        }}<span
          class="price-unit font-style-semi"
          [innerHtml]="unit"
          *ngIf="unit"
        ></span
      ></span>
      <span
        class="additional-info dropdown-price"
        *ngIf="!setToRightBold && dropdownPrice"
      >
        ({{ additionalInfo ? showValueWithComma(option[additionalInfo]) : ""
        }}<span class="price-unit" [innerHtml]="unit" *ngIf="unit"></span
        >)</span
      >
      <ng-container *ngIf="!dropdownPrice && !setToRightBold">
        <span class="additional-info no-left-margin"
          >{{ additionalInfo ? ", " + option[additionalInfo] : ""
          }}<span class="priceUnit" *ngIf="unit" [innerHtml]="unit"></span
        ></span>
      </ng-container>
    </div>
  </ng-scrollbar>
  <span class="label" *ngIf="activeOption">{{ label }}</span>
</div>
<p class="error-msg">{{ "fieldReq" | translate }}</p>
