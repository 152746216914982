import { Injectable } from "@angular/core";
import { Order } from "@dto";
import { environment } from "environments/environment";
import { AnalyticsBaseService } from "./abstract/analytics-base.service";

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAdsAnalyticsService extends AnalyticsBaseService
{
  constructor() { super(); }

  public getName(): string
  {
    return 'GoogleAdsAnalyticsService';
  }

  public isEnabled(): boolean
  {
    return (typeof gtag !== 'undefined');
  }

  public initialize(): Promise<void>
  {
    if ((window as any).gtagScriptPromise == null)
    {
      (window as any).gtagScriptPromise = new Promise<void>((resolve, reject) =>
      {
        //console.info("gtag initialize start " + Date.now());

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.onload = () =>
        {
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).gtag = function () { (window as any).dataLayer.push(arguments); };

          gtag("consent", "default",
            {
              'ad_storage': "denied",
              'analytics_storage': "denied",
              'ad_user_data': "denied",
              'ad_personalization': "denied",
              'wait_for_update': 500
            });



          gtag("js", new Date());
          gtag('set', 'url_passthrough', true); //https://developers.google.com/tag-platform/security/guides/consent?hl=pl&consentmode=advanced
          gtag("config", environment.analytics.googleAdsPropertyID, { 'allow_enhanced_conversions': true });
          //gtag("config", environment.analytics.googleAdsConversionID, { 'allow_enhanced_conversions': true });
          gtag("config", environment.analytics.googleGA4PropertyID, { 'send_page_view': false });

          gtag("config", environment.analytics.googleAdsPropertyID, { 'groups': environment.analytics.googleAdsAndGA4 });
          gtag("config", environment.analytics.googleGA4PropertyID, { 'groups': environment.analytics.googleAdsAndGA4 });

          this.loadAdditionalScript();

          resolve();
        }
        script.onerror = () => reject(new Error('Failed to load gtag script'));
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analytics.googleGA4PropertyID;

        document.getElementsByTagName('body')[0].appendChild(script);
      });
    }

    return (window as any).gtagScriptPromise;
  }

  public loadAdditionalScript()
  {
    const additionalScript = document.createElement('script');
    additionalScript.type = 'text/javascript';
    additionalScript.src = '//cdn.cookie-script.com/s/0351a52dd755ff5de343beaebdc562eb.js';
    document.getElementsByTagName('body')[0].appendChild(additionalScript);
  }

  //#region Transaction
  public async registerTransaction(order: Order): Promise<void>
  {
    // gtag('set', 'user_data', {
    //   email: order.contactAddress.email,
    //   phone_number: order.contactAddress.phone
    // });

    // gtag("event", "conversion", {
    //   send_to: environment.analytics.googleAdsConversionID,
    //   currency: "PLN",
    //   value: (order.productsTotalBrutto / 1.23).toFixed(2),
    //   transaction_id: order.iD
    // });
  }
  //#endregion

  public async registerUserData(email: string, phone: string): Promise<void>
  {
    //   gtag('set', 'user_data', {
    //     email: email,
    //     phone_number: phone
    //   });
  }
}