import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ShopModeService
{

  public isKiosk: boolean = false;

  constructor(private meta: Meta)
  {
  }

  public setKioskMode(): void
  {
    this.isKiosk = true;
    document.body.classList.add('kiosk-mode');
  }

  public isKioskMode(): boolean
  {
    return this.isKiosk;
  }
}
