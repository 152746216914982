<ng-container *ngIf="productFamily">

  <div class="image-wrapper" *ngIf="productFamily" (mouseleave)="hideHoover(productFamily)">
    <app-img
      [lazy]="lazy"
      [src]="product['productGroupID'] ? product['productGroupID'] : product.familyCode"
      [height]="height"
      [width]="width"
      [index]="0"
      [style.height.px]="
        height.length > 1
          ? heightSetByResolution
            ? heightSetByResolution
            : getHeightByResolution()
          : null
      "
      [alt]="product.productName"
      [options]="{ product: true, fillCrop: this.fillCrop }"
      (mouseover)="showHoverFunc(product, $event)"
    ></app-img>
  </div>

  <div class="product-details">

    <p class="body-text font-style-light product-name" [ngClass]="{ longName: productFamily?.name.length > 75 }">
      {{ productFamily?.name }}
    </p>

    <span class="promotion-price" [ngClass]="{ 'promotion-price-active':isPromotionActive }">
      {{ simplePrice ? "" : ("singleProduct.from" | translate).concat(" ") }}{{ thousendValuePromotion ? thousendValuePromotion : showValueThousandSeparatorValue(productFamily.promotionPrice) }}
      <span [innerHTML]="removedSpaced"></span>
    </span>

    <span class="standard-price" *ngIf="isPromotionActive">
      {{ simplePrice ? "" : ("singleProduct.from" | translate).concat(" ")}}{{ getPrice(productFamily.standardPrice) }}
      <span [innerHTML]="removedSpaced"></span>
    </span>
  </div>

</ng-container>

<ng-container *ngIf="product && (product.previewImageIndex || product.previewImageIndex == 0) && !productFamily">
  <div class="relative single-product" (mouseleave)="hideHoover(product)">
    <div class="image-wrapper card-image-wrapper">
      <div
        class="product-main-image-wrapper"
        [style.height.px]="
          height.length > 1
            ? heightSetByResolution
              ? heightSetByResolution
              : getHeightByResolution()
            : null"
        [style.width.px]="
          width.length > 1
            ? widthSetByResolution
              ? widthSetByResolution
              : getWidthByResolution()
            : null"
      >
        <div class="product-main-image">
          <app-discount *ngIf="mainImageLoaded && promotionPercent" [isPromotion]="isPromotionActive" [promotionPrice]="promotionPercent"></app-discount>
          <app-img
            [lazy]="lazy"
            [ngClass]="{ 'image-loaded': mainImageLoaded }"
            [src]="product['productGroupID'] ? product['productGroupID'] : product.familyCode"
            [alt]="product.productName"
            [options]="{ product: true }"
            [index]="0"
            [width]="width"
            [height]="height"
            (mouseover)="showHoverFunc(product, $event)"
            (loaded)="setMainImageLoaded()">
          </app-img>
        </div>
      </div>
      <div class="hover-images" style="display: none; width: 0; height: 0">
        <app-img
          [lazy]="!mainImageLoaded"
          [src]="product.productGroupID"
          [index]="product.previewImageIndex"
          [width]="getHoverImageSizes().width"
          [height]="getHoverImageSizes().height"
          [options]="{ product: true, fillCrop: product.isCovering || product.isCarpet || product.isWalkway }"
        >
        </app-img>
      </div>
      <app-product-hover
        *ngIf="showHover"
        [ngClass]="{ 'is-listining': isListing, 'small-size': cardSize === 'small' }"
        [style.width.px]="cardSize === 'big' ? 420 : 230"
        [product]="product"
        [recommendationID]="recommendationID"
        [isListing]="isListing"
        [cardSize]="cardSize"
        [width]="getHoverImageSizes().width"
        [height]="getHoverImageSizes().height"
        (showProductStatusPopup)="showTooltipPopup($event)"
      ></app-product-hover>
    </div>

    <div class="body-text font-style-light product-name"
      [ngClass]="{ longName: product.productName.length > 75, placeholder: product.productName == '' }"
      [innerText]="product.productName"
    ></div>
    <div class="price-container">

      <span
        class="promotion-price"
        [ngClass]="{ 'promotion-price-active': isPromotionActive, placeholder: product.productName == '' }" >
        <ng-container *ngIf="product.productName != ''">
          {{ "singleProduct.from" | translate }}
        </ng-container>
        {{ thousendValuePromotion ? thousendValuePromotion : showValueThousandSeparatorValue(product?.headerPromotionPrice) }}
        <span [innerHTML]="removedSpaced" ></span>
      </span>

      <span class="standard-price" *ngIf="isPromotionActive">
        {{ thousendValueStandard ? thousendValueStandard : showValueThousandSeparatorValue(product?.headerStandardPrice) }}
        <span [innerHTML]="removedSpaced"></span>
      </span>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="product && product.previewImageIndex == null && !productFamily">
  <div class="show-more-product image-wrapper blank-page"
    [style.height.px]="getHeightByResolution()"
    [style.width.px]="getWidthByResolution()">
    <div class="link-container">
      <p class="success-link last-item">{{ "showMore" | translate }}</p>
    </div>
  </div>
</ng-container>
