<div
  class="navbar-wrapper container"
  [ngClass]="{
    'cart-page': isCartPage && !isFinalStepOfCheckout,
    'is-windows':
      windowRef.getNativeWindow().navigator.platform.indexOf('Win') > -1
  }"
>
  <div class="left-part">
    <div
      class="not-menu-wrapper"
      *ngIf="(!isCartPage || isFinalStepOfCheckout) && headerLinks && headerLinks.length"
    >
      <div class="navbar-info">
        <span class="tiny-text font-style-book color-body-text hover-success">
          <a [routerLink]="headerLinks[0].url">
            {{ headerLinks[0].text }}
          </a>
        </span>
        <span class="tiny-text font-style-book color-body-text hover-success">
          <a [routerLink]="headerLinks[1].url">
            {{ headerLinks[1].text }}</a
          ></span
        >
      </div>
    </div>

    <div
      *ngIf="!isCartPage"
      class="menu-hamburger"
      (click)="openMobileMenu()"
    >
      <i class="ai ai-menu"></i>
    </div>

    <div class="logo-wrapper">
      <a [routerLink]="['/']" (click)="scrollTopIfMainPage()"><img loading="eager" src="/assets/icons/arte.svg" [alt]="siteName"/></a>
    </div>

    <div
      class="menu"
      [ngClass]="{
        hidden: searchExpanded || (isCartPage && !isFinalStepOfCheckout),
        'is-product-page': isProductPage
      }"
    >
      <app-menu
        #menu
        sdClickOutside
        *ngIf="!isMobile"
      ></app-menu>
      <div class="sticky-header-menu">
        <a id="productWrapperLink" (click)="scrollToElement('productWrapper')">
          {{ "navbar.productDescription" | translate }}</a
        >
        <a id="reviewsLink" (click)="scrollToElement('reviews')">{{
          "navbar.reviews" | translate
        }}</a>
        <a
          *ngIf="translateService.currentLang === 'pl' || canBuyWithInstallment"
          id="paymentsLink"
          (click)="scrollToElement('payments')"
          >{{ "navbar.installment" | translate }}</a
        >
        <a
          *ngIf="links && links.complementary"
          id="complementaryLink"
          (click)="scrollToElement('complementary')"
          >{{ "navbar.accessory" | translate }}</a
        >
        <hr id="hr-navigation-line" />
      </div>
    </div>
  </div>
  <div class="right-part">
    <div class="panel-above" *ngIf="!isCartPage || isFinalStepOfCheckout">
      <div class="element-wrapper">
        <a
          class="link"
          [routerLink]="'/' + urlTranslateService.routingTable.additional.pos"
        >
          <div class="image-wrapper main-color">
            <i class="ai ai-pin"></i>
          </div>
          <div class="pos tiny-text main-color font-style-semi">
            {{ "navbar.findShop" | translate }}
          </div>
        </a>
      </div>
      <div
        class="element-wrapper"
        (mouseleave)="
          setPopupVisibility('contactPopup', false);
          changeContactRoutedVar(false)
        "
      >
        <a
          class="link"
          (click)="contactPopup.hide(); changeContactRoutedVar(true)"
          [routerLink]="
            '/' + urlTranslateService.routingTable.additional.contact
          "
          (mouseover)="
            justRoutedToContact === false &&
              setPopupVisibility('contactPopup', true)
          "
        >
          <div class="image-wrapper">
            <i class="ai ai-phone"></i>
          </div>
          <div class="contact tiny-text font-style-semi">
            {{ "contact.title" | translate }}
          </div>
        </a>
        <app-contact-popup
          #contactPopup
          (mouseover)="clearTimeout()"
        ></app-contact-popup>
      </div>
      <div
        class="element-wrapper"
        (mouseleave)="
          setPopupVisibility('accountPopup1', false);
          changeAccountRoutedVar(false)
        "
      >
        <button
          class="link"
          (click)="onMyAccountClick($event)"
          (mouseover)="
            justRoutedToAccount === false && onMyAccountMouseover($event)
          "
        >
          <div class="image-wrapper">
            <i class="ai ai-account"></i>
          </div>
          <div class="account tiny-text font-style-semi">
            {{ "account.title" | translate }}
          </div>
        </button>
        <app-account-popup
          #accountPopup1
          (mouseover)="clearTimeout()"
        ></app-account-popup>
      </div>
      <div
        class="element-wrapper favourite-element-wrapper"
        [ngClass]="{ animation: blink, 'stop-animation': !blink }"
        (mouseleave)="setPopupVisibility('favPopup1', false)"
      >
        <a
          class="link"
          [routerLink]="
            '/' + urlTranslateService.urlMapping.customer.account.favourite
          "
          (click)="goToFavourite($event, 'favPopup1')"
          (mouseover)="stopBlink(); setPopupVisibility('favPopup1', true)"
        >
          <div
            class="image-wrapper"
            (click)="setPopupVisibility('favPopup1', true)"
          >
            <i class="ai ai-heart"></i>
          </div>
          <div class="favourite-above tiny-text font-style-semi">
            {{ "favourite.title" | translate }}
          </div>
          <span class="cart-items-number upper">{{ favItemsNumber }}</span>
        </a>
        <app-fav-popup #favPopup1 (mouseover)="clearTimeout()"></app-fav-popup>
      </div>
      <div
        class="element-wrapper"
        (mouseleave)="setPopupVisibility('cartPopup1', false)"
      >
        <a
          class="link"
          [routerLink]="'/' + urlTranslateService.routingTable.checkout.base"
          (mouseover)="setPopupVisibility('cartPopup1', true)"
        >
          <div class="image-wrapper">
            <i class="ai ai-cart"></i>
          </div>
          <div class="cart-above tiny-text font-style-semi">
            {{ "cart.title" | translate }}
          </div>
          <span
            class="cart-items-number upper"
            [ngClass]="{ 'more-space': cartItemsNumber > 9 }"
            >{{ cartItemsNumber }}</span
          >
        </a>
        <app-cart-popup
          #cartPopup1
          (mouseover)="clearTimeout()"
        ></app-cart-popup>
      </div>
    </div>
    <div class="icons-wrapper" [ngClass]="{ hidden: isCartPage && !isFinalStepOfCheckout }">
      <div
        class="header-icon search navbar-search"
        [ngClass]="{ visible: searchExpanded }"
      >
        <app-search
          [disabled]="searchIsActive"
          #searchComponent
          [childId]="'navbar'"
          (openMenuAndFocusOnSearchInputEmitter)="
            openMobileMenu(true)
          "
        ></app-search>
        <i
          class="ai ai-cancel cancel-button"
          [ngClass]="{ hidden: !searchExpanded }"
          (click)="setExpandSearch(false)"
        ></i>
      </div>
      <div
        class="header-icon search-icon"
        (click)="setExpandSearch(true)"
        [ngClass]="{ hidden: searchExpanded }"
      >
        <i class="ai ai-search icon"></i>
      </div>
      <div
        class="header-icon account-icon"
        (mouseover)="setPopupVisibility('accountPopup2', true)"
        (mouseleave)="setPopupVisibility('accountPopup2', false)"
        (click)="goToAccount($event)"
      >
        <i class="ai ai-account icon"></i>
        <app-account-popup
          #accountPopup2
          (mouseover)="clearTimeout()"
        ></app-account-popup>
      </div>
      <div
        class="header-icon favourite"
        [ngClass]="{ animation: blink, 'stop-animation': !blink }"
        (click)="goToFavourite($event, 'favPopup2')"
        (mouseover)="stopBlink(); setPopupVisibility('favPopup2', true)"
        (mouseleave)="setPopupVisibility('favPopup2', false)"
        sdClickOutside
        (clickOutside)="setPopupVisibility('favPopup2', false)"
      >
        <!--fav component-->
        <i class="ai ai-heart icon"></i>
        <span class="cart-items-number">{{ favItemsNumber }}</span>
        <app-fav-popup #favPopup2 (mouseover)="clearTimeout()"></app-fav-popup>
      </div>
      <div
        class="header-icon cart"
        (mouseover)="setPopupVisibility('cartPopup2', true)"
        (mouseleave)="setPopupVisibility('cartPopup2', false)"
        (click)="goToCheckout($event)"
      >
        <i class="ai ai-cart icon"></i>
        <span class="cart-items-number">{{ cartItemsNumber }}</span>
        <app-cart-popup
          #cartPopup2
          (mouseover)="clearTimeout()"
        ></app-cart-popup>
      </div>
    </div>
    <div
      class="steps-wrapper"
      [ngClass]="{ hidden: !isCartPage || (isCartPage && isFinalStepOfCheckout) }"
    >
      <div
        class="step-container"
        [ngClass]="{ active: activeStep == 'first' && !isServer }"
        (click)="goToStep('/' + urlTranslateService.routingTable.checkout.base)"
      >
        <div
          class="first-step button primary-button circle empty-inside border-success"
        >
          1
        </div>
        <p class="extended-text-tablet">
          {{ "navbar.cartDataTablet1" | translate }}
          <span> {{ "navbar.cartDataTablet2" | translate }}</span>
        </p>
      </div>
      <span class="horizontal-separator"></span>
      <div
        class="step-container"
        [ngClass]="{ active: activeStep == 'second' && !isServer }"
        (click)="
          goToStep('/' + urlTranslateService.urlMapping.checkout.account)
        "
      >
        <div
          class="second-step button primary-button circle empty-inside border-success"
        >
          2
        </div>
        <p class="extended-text-tablet">
          {{ "navbar.orderDataTablet1" | translate }}
          <span> {{ "navbar.orderDataTablet2" | translate }}</span>
        </p>
      </div>
      <span class="horizontal-separator"></span>
      <div
        class="step-container"
        [ngClass]="{ active: activeStep == 'third' && !isServer }"
        (click)="
          goToStep('/' + urlTranslateService.urlMapping.checkout.summary)
        "
      >
        <div
          class="third-step button primary-button circle empty-inside border-success"
        >
          3
        </div>
        <p class="extended-text-tablet">{{ "navbar.summary" | translate }}</p>
      </div>
    </div>
    <div class="help-section-wrapper">
      <p>{{ "navbar.needHelp" | translate }}</p>
      <div>
        <a [registerClickEvent] href="tel:801090905">801 09 09 05</a> /
        <a [registerClickEvent] href="tel:221002727">22 100 27 27</a>
      </div>
    </div>
  </div>
</div>
