<ng-container *ngIf="!placeholder">

  <div class="font-style-semi title">
    {{ headingTitle }}
  </div>

  <ng-container *ngIf="!product.isArchived && addPerfectScroll">
    <ng-container *ngTemplateOutlet="variantsHeader"></ng-container>
    <ng-scrollbar *ngIf="product" class="attributes-wrapper" autoHeightDisabled="false">
      <ng-container *ngTemplateOutlet="variants"></ng-container>
    </ng-scrollbar>
  </ng-container>

  <ng-container *ngIf="!placeholder && !addPerfectScroll">
    <ng-container *ngIf="!product.isArchived">
      <ng-container *ngTemplateOutlet="variantsHeader"></ng-container>
      <div class="attributes-wrapper" *ngIf="product">
        <ng-container *ngTemplateOutlet="variants"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="product.isArchived">
      <div class="attributes-wrapper" *ngIf="product">
        <ng-container *ngTemplateOutlet="variantsHeader"></ng-container>
        <ng-container
          *ngFor="let productVariant of productArchived; let i = index"
        >
          <div class="flex-wrapper-checkbox">
            <div class="size-col">
              <span class="checkmark"></span>
              <span class="font-style-light color-body-text option"
                >{{ productVariant.option }}
              </span>
            </div>
            <div class="price-col price-mt-mobile">
              <span
                class="promotion-price"
                *ngIf="product.products[0].standardPricePerSquareMeters > 0"
                >{{
                  (
                    product.products[0].standardPricePerSquareMeters *
                    productVariant.size
                  ).toFixed(0)
                }}
                {{ "general.currency" | translate }}</span
              >
              <span
                class="promotion-price"
                *ngIf="product.products[0].standardPricePerSquareMeters == 0"
                >{{ (200 * productVariant.size).toFixed(0) }}
                {{ "general.currency" | translate }}</span
              >
            </div>
            <div class="time-col">
              <div class="time-col-content">
                <span class="time-description">24 h</span>
                <span class="tooltip-wrapper">
                  <i class="ai ai-question-mark"></i>
                  <div class="tooltip-availability"></div>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #variantsHeader>
    <div *ngIf="addVariantsHeader" class="header-name">
      <span>{{ "productVariants.size" | translate }}</span>
      <span>{{ "productVariants.price" | translate }}</span>
      <span *ngIf="!popupReservation">{{
        "productVariants.shippingTime" | translate
      }}</span>
      <span *ngIf="popupReservation">{{
        "productVariants.availbility" | translate
      }}</span>
    </div>
  </ng-template>

  <ng-template #variants>
    <ng-container *ngIf="product.products">
      <ng-container *ngFor="let productVariant of product.products; let i = index">
        <div class="flex-wrapper-checkbox"
          *ngIf=" productVariant.option !== '000' && canShowOnOnlyTableView(i) &&
          ((product.isCovering && (productVariant.isUnique == true || doesntMatterIfUnique == true)) || !product.isCovering)"
          [ngClass]="{ 'last-element': i == product.products.length - 1 && addPerfectScroll }">
          <div class="size-col" (click)="setElementAsSelected(productVariant)">
            <input
              type="checkbox"
              class="primary-checkbox"
              *ngIf="!onlyTableView"
              [checked]="(productVariant['selectedModal'] && variantsModal) || (productVariant.selected && !variantsModal)"/>
            <span class="checkmark" *ngIf="!onlyTableView"></span>
            <span class="font-style-light color-body-text option"
              >{{ productVariant.option }}
            </span>
          </div>

          <div
            class="price-col"
            [ngClass]="{ 'price-mt-mobile': !isPromotionActive(productVariant) }"
            (mouseenter)=" changeTooltipState(i, 'activePromotionTooltip', $event) "
            (mouseleave)=" changeTooltipState(i, 'activePromotionTooltip', $event, true) "
            (click)="changeTooltipState(i, 'activePromotionTooltip', $event)">
            <div class="prices">
              <span class="promotion-price" [ngClass]="{ 'promotion-price-active': isPromotionActive(productVariant) }" >
                {{ productVariant.promotionPrice | valueWithComma }}
                <span [innerHtml]="productVariant.priceUnit"></span>
                <app-tooltip-custom
                  sdClickOutside
                  *ngIf="isPromotionActive(productVariant) && i === activePromotionTooltip"
                  height="auto"
                  width="auto"
                  left="0px"
                  [description]="('omnibus.priceBeforePromotion' | translate) + ': ' + (productVariant.priceBeforePromotion | valueWithComma) + ' ' + productVariant.priceUnit + '<br />' + ('omnibus.promotionStarted' | translate) + ': ' + (productVariant.promotionSince | date : 'dd.MM.yyyy') + ' r.'"
                  (clickOutside)="changeTooltipState(i, 'activePromotionTooltip', $event)">
                </app-tooltip-custom>
              </span>
              <span class="standard-price" *ngIf="isPromotionActive(productVariant)">
                {{ productVariant.standardPrice | valueWithComma }}
                <span [innerHtml]="productVariant.priceUnit"></span>
              </span>
            </div>
            <div class="tooltip-icon flex" *ngIf="isPromotionActive(productVariant)" >
              <i class="ai ai-question-mark"></i>
            </div>
          </div>
          <div class="time-col">
            <div class="time-col-content" (click)="goToTooltipPopup($event, productVariant); changeTooltipState(i, 'activeTooltip', $event, true);" (mouseenter)="changeTooltipState(i)" (mouseleave)="changeTooltipState(i, 'activeTooltip', $event, true)">
              <ng-container *ngIf="selectedShop; else shop">
                <span
                  class="time-description"
                  [innerHTML]="
                    productVariant
                      | getShortTime : selectedShop : isMobile : popupReservation
                  "
                  (window:resize)="checkIsMobile()"
                ></span>
              </ng-container>
              <ng-template #shop>
                <span class="time-description time-description--desktop">
                  {{ productVariant.shippingTime.daysTimeLabel }}
                </span>
                <span class="time-description time-description--mobile">
                  {{ productVariant.shippingTime.daysTimeLabelShort }}
                </span>
              </ng-template>

              <span *ngIf="deliveryTooltip" class="tooltip-wrapper">
                <i class="ai ai-question-mark"></i>
                <div *ngIf="i == activeTooltip" class="tooltip-availability">
                  <app-tooltip
                    [product]="productVariant"
                    (statusProductTooltipInvoked)="goToProductStatusPopup($event)"
                  ></app-tooltip>
                </div>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!product.products">
      <ng-container
        *ngFor="let productVariant of product.variants; let i = index"
      >
        <div
          class="flex-wrapper-checkbox"
          [ngClass]="{
            greyOption:
              !productVariant.active &&
              productVariant.active != undefined &&
              !addPerfectScroll
          }"
          *ngIf="productVariant.option !== '000' && canShowOnOnlyTableView(i)"
        >
          <div class="size-col" (click)="setElementAsSelected(productVariant)">
            <span class="font-style-light color-body-text option"
              >{{ productVariant.option }}
            </span>
          </div>
          <div
            class="price-col"
            [ngClass]="{
              'price-mt-mobile':
                !isPromotionActive(productVariant) && addPerfectScroll
            }"
          >
            <div class="prices">
              <span
                class="promotion-price"
                [ngClass]="{
                  'promotion-price-active': isPromotionActive(productVariant)
                }"
                >{{ productVariant.promotionPrice | valueWithComma }}
                <span [innerHtml]="productVariant.priceUnit"></span
              ></span>
              <span
                class="standard-price"
                *ngIf="isPromotionActive(productVariant)"
                >{{ productVariant.standardPrice | valueWithComma }}
                <span [innerHtml]="productVariant.priceUnit"></span
              ></span>
            </div>
          </div>
          <div class="time-col">
            <div class="time-col-content" (click)="goToTooltipPopup($event, productVariant); changeTooltipState(i, 'activeTooltip', $event, true);" (mouseenter)="changeTooltipState(i)" (mouseleave)="changeTooltipState(i, 'activeTooltip', $event, true)">
              <span
                class="time-description time-description--desktop"
              >
                {{ productVariant.shipmentTimeShort }}
              </span>
              <span *ngIf="deliveryTooltip" class="tooltip-wrapper">
                <i class="ai ai-question-mark"></i>
                <div *ngIf="i == activeTooltip" class="tooltip-availability">
                  <app-tooltip
                    [product]="productVariant"
                    (statusProductTooltipInvoked)="goToProductStatusPopup($event)"
                  ></app-tooltip>
                </div>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="placeholder">
    <div class="font-style-semi title placeholder-text"></div>
    <div class="attributes-wrapper">
      <div class="header-name">
        <span>{{ "productVariants.size" | translate }}</span>
        <span>{{ "productVariants.price" | translate }}</span>
        <span *ngIf="!popupReservation">{{
          "productVariants.shippingTime" | translate
        }}</span>
        <span *ngIf="popupReservation">{{
          "productVariants.availbility" | translate
        }}</span>
      </div>
      <div class="flex-wrapper-checkbox" *ngFor="let i of [].constructor(7)">
        <div class="size-col">
          <input type="checkbox" class="primary-checkbox" />
          <span class="checkmark"></span>
          <div class="font-style-semi placeholder-text"></div>
        </div>
        <div class="price-col">
          <div class="font-style-semi placeholder-text"></div>
        </div>
        <div class="font-style-semi placeholder-text"></div>
        <span>
          <i class="ai ai-question-mark"></i>
        </span>
      </div>
    </div>
  </ng-container>

</ng-container>
