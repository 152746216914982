import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from "@angular/core";
import { ShopProduct, ShopProductFamily } from "@dto";
import { Record } from "@idto";
import { Resolutions } from "@resolutions";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { ProductsService } from "@service/products/products.service";

@Component({
  selector: "app-single-product",
  templateUrl: "./single-product.component.html",
  styleUrls: ["./single-product.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleProductComponent
{
  private _product: Record;
  private _productFamily: ShopProduct;

  @Input() simplePrice: boolean
  @Input() public recommendationID: string;
  @Input() public set product(value: Record)
  {
    this._product = value;
    this.removedSpaced = this._product?.headerPriceUnit?.replace(/\s/g, "");
    this.isPromotionActive = this._product?.headerStandardPrice !== this._product?.headerPromotionPrice;

    if (this._product && this._product.headerStandardPrice)
    {
      this.promotionPercent = (((Number.parseFloat(this._product.headerStandardPrice) - Number.parseFloat(this._product.headerPromotionPrice)) / Number.parseFloat(this._product.headerStandardPrice)) * 100).toFixed(0);
    }
  }
  public get product(): Record
  {
    return this._product;
  }
  @Input() public set productFamily(value: ShopProduct)
  {
    this._productFamily = value;
    this.removedSpaced = this._productFamily?.priceUnit?.replace(/\s/g, "");
  }
  public get productFamily(): ShopProduct
  {
    return this._productFamily;
  }
  @Input() public isObservable: boolean = false;
  @Input() public isListing: boolean = false;
  @Input() public cardSize: "big" | "small" | "none" = "none";
  @Input() public width: number[] = [];
  @Input() public height: number[] = [];
  @Input() public fillCrop: boolean = false;
  @Input() public lazy: boolean = false;

  @Output() private readonly showProductStatusPopup: EventEmitter<ShopProduct> = new EventEmitter();

  public showHover: boolean;
  private ishoverTimeout: boolean;
  private hoverTimeout: any;
  public thousendValueStandard: any;
  public thousendValuePromotion: any;
  public removedSpaced: any;
  public isPromotionActive: boolean;
  public heightSetByResolution: any;
  public widthSetByResolution: any;
  public showDiscount: boolean = true;
  public mainImageLoaded: boolean = false;
  public promotionPercent: string;

  constructor(
    private renderer2: Renderer2,
    private searchService: SearchRepositoryService,
    private windowRef: WindowRefService,
    private changeDet: ChangeDetectorRef,
    private elementRef: ElementRef,
    public productsService: ProductsService
  ) { }

  public showHoverFunc(product, $event): void
  {
    if (!this.ishoverTimeout && this.cardSize !== "none")
    {
      this.ishoverTimeout = true;
      this.hoverTimeout = setTimeout(() =>
      {
        this.ishoverTimeout = false;
        this.showHover = true;
        if (
          this.windowRef.getNativeWindow().innerWidth >
          Resolutions.resolutionDesktop
        )
        {
          this.showDiscount = false;
        }
        setTimeout(() =>
        {
          const hoverElement =
            this.elementRef.nativeElement.querySelector("app-product-hover");
          if (hoverElement)
          {
            this.renderer2.addClass(hoverElement, "opa-active");
          }
          this.changeDet.detectChanges();
        }, 10);
        this.changeDet.detectChanges();
      }, 250);
    }
  }

  public getPrice(price): string
  {
    return parseFloat(price).toFixed(0);
  }

  public hideHoover(product): void
  {
    clearTimeout(this.hoverTimeout);
    this.showHover = false;
    this.showDiscount = true;
    this.ishoverTimeout = false;
    const hoverElement = this.elementRef.nativeElement.querySelector('app-product-hover');
    if (hoverElement)
    {
      this.renderer2.removeClass(hoverElement, 'opa-active');
    }
    this.changeDet.detectChanges();
  }

  public setMainImageLoaded(): void
  {
    this.mainImageLoaded = true;
    this.changeDet.detectChanges();
  }

  public showValueThousandSeparatorValue(value: any): string
  {
    if (value === 0 || !value)
    {
      return "";
    }
    let temp = parseFloat(value).toFixed(2).replace(/\./gi, ",");
    if (temp.indexOf(",") != -1)
    {
      temp = temp.split(",")[0];
    }
    return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public showTooltipPopup($event: any): void
  {
    this.showProductStatusPopup.emit($event);
  }

  @HostListener("window:resize", ["$event"])
  private onWindowResize(): void
  {
    let changed = false;
    if (this.height.length > 1)
    {
      const previousHeight = this.heightSetByResolution;
      const newHeight = this.getHeightByResolution();
      changed ||= previousHeight !== newHeight;
    }
    if (this.width.length > 1)
    {
      const previousWidth = this.widthSetByResolution;
      const newWidth = this.getWidthByResolution();
      changed ||= previousWidth !== newWidth;
    }

    if (changed)
    {
      this.changeDet.detectChanges();
    }
  }

  public getHeightByResolution(): any
  {
    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet)
    {
      this.heightSetByResolution = this.height[0].toString();
      return this.heightSetByResolution;
    }
    else if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.heightSetByResolution = this.height[1].toString();
      return this.heightSetByResolution;
    }
    else
    {
      this.heightSetByResolution = this.height[2].toString();
      return this.heightSetByResolution;
    }
  }

  public getWidthByResolution(): any
  {
    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet)
    {
      this.widthSetByResolution = this.width[0];
      return this.widthSetByResolution;
    }
    else if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.widthSetByResolution = this.width[1];
      return this.widthSetByResolution;
    }
    else
    {
      this.widthSetByResolution = this.width[2];
      return this.widthSetByResolution;
    }
  }

  public getHoverImageSizes(): { width: number[], height: number[] }
  {
    if (this.product)
    {
      if (this.product.productGroupID.includes("AW-"))
      {
        return {
          width: null,
          height: [270, 270, 270]
        }
      } else
      {
        switch (this.cardSize)
        {
          case 'big':
            return {
              width: [420, 420, 420],
              height: [270, 270, 270]
            }

          case 'small':
            return {
              width: [230, 230, 230],
              height: [115, 115, 115]
            }

          default:
            return {
              width: [300, 300, 300],
              height: [150, 150, 150]
            }
        }
      }
    }
  }
}
