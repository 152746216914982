import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-calculator',
  templateUrl: './input-calculator.component.html',
  styleUrls: ['./input-calculator.component.scss']
})
export class InputCalculatorComponent implements OnChanges, OnInit
{
  get value(): any
  {
    return this._value;
  }

  @Input() set value(value: any)
  {
    this._value = value;
    if (!value)
    {
      this._value = this.defaultOperationValue;
    }
    this.setDisplayedValue(this._value, false);
  }

  private _value: any;
  @ViewChild('inputValue', { static: true }) public inputValue: ElementRef;
  @Input() private defaultOperationValue: number = 10;
  @Input() private productName: string;
  @Input() public productUnit: string;
  @Input() public hidePlusAndMinus: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public inputValueName: string;
  @Input() public suffix: string;
  @Input() public fraction: number = 2;
  @Output() private readonly valueChange: EventEmitter<number> = new EventEmitter<number>();

  public realValue: string = '';
  public showArrows: boolean = false;

  ngOnInit(): void
  {
    this.showArrows = (this.productUnit == "pcs" || this.productUnit == "szt.") && this.suffix == '';
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.productName)
    {
      if (changes.productName.currentValue !== changes.productName.previousValue)
      {
        this._value = this.defaultOperationValue;
        this.setDisplayedValue(this._value, false);
      }
    }

    if (changes.fraction || changes.suffix)
    {
      this.setDisplayedValue(this.value, false);
    }
  }

  public additionValue(): void
  {
    if (this.hidePlusAndMinus)
    {
      return;
    }
    this._value += this.defaultOperationValue;
    if (this.fraction >= 2)
    {
      this._value = Math.round(this._value * 100) / 100;
    } else
    {
      this._value = Math.round(this.value);
    }
    this.setDisplayedValue(this._value, true);
  }

  public subtractionValue(disabled): void
  {
    if (this.disabled || this.hidePlusAndMinus || disabled)
    {
      return;
    }
    if (this._value - this.defaultOperationValue > 0)
    {
      this._value -= this.defaultOperationValue;
      if (this.fraction >= 2)
      {
        this._value = Math.round(this._value * 100) / 100;
      } else
      {
        this._value = Math.round(this.value);
      }
    } else
    {
      this._value = this.defaultOperationValue;
    }
    this.setDisplayedValue(this._value, true);
  }

  public changeByEnter($event): void
  {
    if ($event.keyCode == 13)
    {
      let value = parseFloat(this.inputValue.nativeElement.value.replace(/,/gi, '.'));

      if (isNaN(value) || value === 0)
      {
        value = this._value;
      }

      if (this.fraction > 0)
      {
        this._value = Math.round(value * 100) / 100;
      } else
      {
        this._value = Math.round(value);
      }
      this.setDisplayedValue(this._value, true);
      $event.stopPropagation();
      return;
    }
  }

  public changeByBlur(): void
  {
    let value = parseFloat(this.inputValue.nativeElement.value.replace(/,/gi, '.'));

    if (isNaN(value) || value === 0)
    {
      value = this._value;
    }

    if (this.fraction > 0)
    {
      this._value = Math.round(value * 100) / 100;
    } else
    {
      this._value = Math.round(value);
    }

    this.setDisplayedValue(this._value, true);
    return;
  }

  public isDefaultValueOfValue(): boolean
  {
    return this._value <= this.defaultOperationValue;
  }

  private setDisplayedValue(value, emit: boolean): void
  {
    if (value != undefined)
    {
      if (isNaN(value))
      {
        value = this.defaultOperationValue;
      }
      this.realValue = parseFloat(value).toFixed(this.fraction).replace(/\./gi, ',') + this.suffix;
      //this.inputValue.nativeElement.value = parseFloat(value).toFixed(this.fraction).replace(/\./gi, ',') + this.suffix;
      this.setValue(value, emit);
    }
  }

  private setValue(value, emit: boolean): void
  {
    this._value = value;

    if (emit)
    {
      this.valueChange.emit(this._value);
    }
  }
}
