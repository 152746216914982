import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';
import { ScrollPositionService } from '@service/scrollPosition.service';
import { ErrorsModule } from '@shared/errors/errors.module';
import { Navbar_Overlay_Module } from '@shared/navbar_overlay_module/navbar_overlay.module';
import { SharedModule } from '@shared/shared/shared.module';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppPreloadingStrategy } from './utils/app_preloading_strategy';
import { CustomTranslateLoader } from '@service/translate-loaders/custom-translate-loader'
import { TranslationLoaderResolver } from './module/resolver/translate-resolver.service';
import { BlockService } from '@shared/block/shared/block.service';
import { ChatComponent } from './module/chat/chat.component'
import { ShopModule } from '@shop/shop.module';
import { ProductModule } from '@shop/product/product.module';

@NgModule({
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'app' }), // .withServerTransition({ appId: 'app' }),
    HttpClientModule,
    RouterModule,
    ShopModule,
    ProductModule,
    Navbar_Overlay_Module,
    NgScrollbarModule,
    SwiperModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: CustomTranslateLoader, deps: [BlockService] }
    }),
    //todo: wyciagnac do configu w env
    IntercomModule.forRoot({
      appId: "ffwyw47n", // from your Intercom config
      updateOnRouterChange: false, // will automatically run `update` on router event changes. Default: `false`
    }),
    ErrorsModule,
  ],
  declarations: [
    AppComponent,
    ChatComponent
  ],
  providers: [
    AppPreloadingStrategy,
    TranslationLoaderResolver,
    ScrollPositionService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    //{ provide: 'RollbarService', useFactory: rollbarFactory, deps: [] }
    // {
    //   deps: [DOCUMENT, PLATFORM_ID],
    //   multi: true,
    //   provide: APP_INITIALIZER,
    //   useFactory: function (document: HTMLDocument, platformId: Object): Function
    //   {
    //     // return () =>
    //     // {ng
    //     //   if (isPlatformBrowser(platformId))
    //     //   {
    //     //     console.log('preboot start');
    //     //     // const dom = ɵgetDOM();
    //     //     // const styles: any[] = Array.prototype.slice.apply(dom.querySelectorAll(document, `style[ng-transition]`));
    //     //     document.body.className += ' isServer ';
    //     //     // styles.forEach(el => {
    //     //     //   Remove ng-transition attribute to prevent Angular appInitializerFactory
    //     //     //   to remove server styles before preboot complete
    //     //     // el.removeAttribute('ng-transition');
    //     //     // });
    //     //     document.addEventListener('PrebootComplete', () =>
    //     //     {
    //     //       console.log('preboot complate');
    //     //       // After preboot complete, remove the server scripts
    //     //       setTimeout(() =>
    //     //       {
    //     //         // styles.forEach(el => dom.remove(el));
    //     //         // console.log('start ie event making');
    //     //         document.body.className = document.body.className.replace('isServer', ' isBrowser ');
    //     //         window.dispatchEvent(createNewEvent('resize'));

    //     //         function createNewEvent(eventName): any
    //     //         {
    //     //           var event;
    //     //           if (typeof (Event) === 'function')
    //     //           {
    //     //             event = new Event(eventName);
    //     //           } else
    //     //           {
    //     //             event = document.createEvent('Event');
    //     //             event.initEvent(eventName, true, true);
    //     //           }
    //     //           return event;
    //     //         }
    //     //       });
    //     //     });
    //     //   }
    //     // };
    //   }
    //}

  ],
  bootstrap: [AppComponent],
})
export class AppModule
{
}
