<div
  class="modal-wrapper"
  *ngIf="showForm"
  [ngClass]="{ loaded: loaded && loaded['loaded'] }"
>
  <div class="wrapper scroll">
    <div
      class="under-wrapper"
      [ngClass]="{ loaded: loaded && loaded['loaded'] }"
      sdClickOutside
      (clickOutside)="closeModal($event)"
      #parent
      [parentRef]="parent"
    >
      <div class="img-wrapper cancel" (click)="closeModal($event)">
        <i class="ai ai-cancel"></i>
      </div>
      <div class="content-wrapper" *ngIf="boughtItems.length">
        <div class="row" *ngFor="let item of boughtItems">
          <div class="image-wrapper">
            <app-img
              [src]="product.familyCode"
              [width]="null"
              [height]="[45, 45, 45]"
              [index]="0"
              [options]="{
                product: true
              }"
              [alt]="item.name"
            ></app-img>
          </div>
          <div class="mobile-wrapper">
            <p class="caption" *ngIf="!windowRef.isMobile()">
              {{ "cart.added" | translate }}:
            </p>
            <p class="caption" *ngIf="windowRef.isMobile()">
              {{ "cart.added" | translate }}.
            </p>
            <p class="product-name from-tablet">
              {{ item.name
              }}<span *ngIf="item.isCovering && !item.isWalkway">
                {{ item.option }}</span
              >
            </p>
            <p class="product-price from-tablet">
              {{ item["summaryPrice"] | valueWithComma}}
              {{ "general.currency" | translate }}
            </p>
          </div>
        </div>
        <div class="separator-big"></div>
        <section *ngIf="!hideAccessories" class="suggested-accessory">
          <p class="title additional-title">
            {{ "cart.suggestedAccessory" | translate }}
          </p>
          <div class="additional-wrapper">
            <swiper [config]="swiperOpt" class="slider-main">
              <ng-template
                class="single-product swiper-slide"
                *ngFor="let acp of accessoryProduct; let i = index"
                swiperSlide
              >
                <a
                  class="product-link"
                  (click)="openInNewCard(acp.url, $event)"
                  [href]="[
                    '/' +
                      urlTranslateService.routingTable.shop.product +
                      '/' +
                      acp.url
                  ]"
                >
                  <app-single-product
                    [product]="acp"
                    [productFamily]="acp"
                    [simplePrice]="true"
                    [height]="[180, 180, 180]"
                  ></app-single-product>
                </a>
                <div
                  class="flex-wrapper-checkbox"
                  (click)="addToCart(acp, isSelected(acp), i)"
                >
                  <input
                    type="checkbox"
                    class="primary-checkbox"
                    [checked]="isSelected(acp)"
                  />
                  <span class="checkmark"></span>
                  <p
                    class="compare-checkbox-label"
                    [ngClass]="{ active: isSelected(acp) }"
                  >
                    {{
                      isSelected(acp)
                        ? ("cart.inCart" | translate)
                        : ("cart.addToCart" | translate)
                    }}
                  </p>
                  <img
                    *ngIf="i === isLoadingIndex"
                    class="loader-icon"
                    src="/assets/icons/load_anim.svg"
                  />
                </div>
              </ng-template>
            </swiper>
          </div>
        </section>
      </div>
      <div class="button-wrapper">
        <div class="success-link back-link">
          <p (click)="closeModal(null)">
            {{ "listing.backToShopping" | translate }}
          </p>
        </div>
        <div class="button primary-button" (click)="goToCheckout()">
          {{ "cart.goToCheckout" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
